// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, collection, addDoc, doc, onSnapshot} from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut, sendSignInLinkToEmail, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { loadStripe,} from "@stripe/stripe-js";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDIHytW6c0lzrnyIw1w0Wn4jFNs-Wvo0_w",
  authDomain: "divinelydesigning.com",
  projectId: "divinelydesigning",
  storageBucket: "divinelydesigning.appspot.com",
  messagingSenderId: "1016166138961",
  appId: "1:1016166138961:web:e048b96c0fdbdc95cce470",
  measurementId: "G-6RCPR7FFFH"
};
//This is a test

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LfcbOEkAAAAAGMkNm5wcdFRW1npZ2knfqk81_-V'),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});


const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');

// init services
const db = getFirestore(app);

const functions = getFunctions(app);

var currentPage = window.location.pathname;

//Google Auth
const provider = new GoogleAuthProvider();
const auth = getAuth();
const user = auth.currentUser;

// To apply the default browser preference instead of explicitly setting it.
// firebase.auth().useDeviceLanguage();

if (currentPage === '/' || currentPage === '/index.html') {

   

  //Add to ContactRequestsCollection
  const addContactForm = document.querySelector('.contactForm')
  const contactModal1 = document.getElementById('submitMessageModal')
  const closeContactModal = document.querySelector('.closeMessageModal')
  const loader = document.getElementById('loader')
  const d = new Date();
  let submissionTime = d.toLocaleString();

  ////////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////

  addContactForm.addEventListener('submit', (e) => {
    e.preventDefault()
    loader.style.display = "block";

    var prefix = addContactForm.prefix.value;
    var firstName = addContactForm.FirstName.value;
    var lastName = addContactForm.LastName.value;
    var email = addContactForm.Email.value;
    var phone = addContactForm.phoneNumarea.value + '-' + addContactForm.phoneNum1st3.value + '-' + addContactForm.phoneNumlast4.value;
    var message = addContactForm.Message.value;
    var timestamp = submissionTime;


    const addContact = httpsCallable(functions, 'newContact');
    addContact({
      prefix: prefix,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone,
      message: message,
      timestamp: timestamp
    }).then(() => {
      loader.style.display = "none";
      addContactForm.reset();
      contactModal1.style.display = "block";
      closeContactModal.addEventListener("click", () => {
        contactModal1.style.display = "none";
      })
    })
  })


} else if (currentPage === '/inspirations.html') {
  //Add to ContactRequestsCollection
  const addmaintenanceForm = document.querySelector('.maintenanceForm')
  const contactModal1 = document.getElementById('submitMessageModal')
  const closeContactModal = document.querySelector('.closeMessageModal')
  const loader = document.getElementById('loader')
  const d = new Date();
  let submissionTime = d.toLocaleString();

  ////////////////////////////////////////
  ////////////////////////////////////////
  ////////////////////////////////////////

  addmaintenanceForm.addEventListener('submit', (e) => {
    e.preventDefault()
    loader.style.display = "block";

    var prefix = addmaintenanceForm.prefix.value;
    var firstName = addmaintenanceForm.FirstName.value;
    var lastName = addmaintenanceForm.LastName.value;
    var website = addmaintenanceForm.Website.value;
    var email = addmaintenanceForm.Email.value;
    var phone = addmaintenanceForm.phoneNumarea.value + '-' + addmaintenanceForm.phoneNum1st3.value + '-' + addmaintenanceForm.phoneNumlast4.value;
    var message = addmaintenanceForm.Message.value;
    var timestamp = submissionTime;


    const addContact = httpsCallable(functions, 'newMaintenance');
    addContact({
      prefix: prefix,
      firstName: firstName,
      lastName: lastName,
      website: website,
      email: email,
      phone: phone,
      message: message,
      timestamp: timestamp
    }).then(() => {
      loader.style.display = "none";
      addmaintenanceForm.reset();
      contactModal1.style.display = "block";
      closeContactModal.addEventListener("click", () => {
        contactModal1.style.display = "none";
      })
    })
  })
} else if (currentPage === '/myAccount.html') {
  // [START auth_google_signin_popup_modular]

  const auth = getAuth();
  const user = auth.currentUser;

  const googleButton = document.querySelector('.googleButton')
  const logoutButton = document.querySelector('.logoutButton')


  const loginOption = document.getElementById('loginSection')
  const profileSection = document.getElementById('profile')

  const highLevUserItems = document.querySelectorAll('.elevatedUser');
  const customerLoader = document.getElementById('customerLoader');
  const loginLoader = document.getElementById('loginLoader');

  const monitorAuthState = async () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        
        setupAdminUI(user);

      } else {
        // No user is signed in.
        loginOption.style.display = "block";
        profileSection.style.display = "none";
        const emailForm = document.querySelector('.emailForm');
        const emailModal = document.getElementById("emailModal");

        
        
        const auth = getAuth();
        if (isSignInWithEmailLink(auth, window.location.href)) {
          // Additional state parameters can also be passed via URL.
          // This can be used to continue the user's intended action before triggering
          // the sign-in operation.
          // Get the email if available. This should be available if the user completes
          // the flow on the same device where they started it.
          loginLoader.style.display = "block";
          let email = window.localStorage.getItem('emailForSignIn');
          console.log(`Email from link is ${email}`);
          if (!email) {
            loginLoader.style.display = "none";
        
            // User opened the link on a different device. To prevent session fixation
            // attacks, ask the user to provide the associated email again. For example:
            email = window.prompt('Please provide your email for confirmation');
          }
          // The client SDK will parse the code from the link for you.
          console.log(`Sign In Begin`);
          signInWithEmailLink(auth, email, window.location.href)
            .then((result) => {
              // Clear email from storage.
              window.localStorage.removeItem('emailForSignIn');
              loginLoader.style.display = "none";
              // You can access the new user via result.user
              // Additional user info profile not available via:
              // result.additionalUserInfo.profile == null
              // You can check if the user is new or existing:
              // result.additionalUserInfo.isNewUser
            })
            .catch((error) => {
              // Some error occurred, you can inspect the code: error.code
              // Common errors could be invalid email and invalid or expired OTPs.
              console.log(error.code);
              console.log(error.message);
            });
        }

        const actionCodeSettings = {
          // URL you want to redirect back to. The domain (www.example.com) for this
          // URL must be in the authorized domains list in the Firebase Console.
          url: 'https://divinelydesigning.com/myAccount.html',
          // This must be true.
          handleCodeInApp: true,
          
        };

        googleButton.addEventListener("click", () => {

          const auth = getAuth();
          signInWithPopup(auth, provider)
            .then((result) => {
              // This gives you a Google Access Token. You can use it to access the Google API.
              const credential = GoogleAuthProvider.credentialFromResult(result);
              const token = credential.accessToken;
              // The signed-in user info.
              const user = result.user;
              // IdP data available using getAdditionalUserInfo(result)
              // ...
            }).catch((error) => {
              if (error.code !== 'auth/internal-error' && error.message.indexOf('Cloud Function') !== -1) {
                console.log("Test Failure");
              } else {
                // Registration succeeds.
              }
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              // The email of the user's account used.
              const email = error.customData.email;
              // The AuthCredential type that was used.
              const credential = GoogleAuthProvider.credentialFromError(error);
              // ...
            });
        })

        emailForm.addEventListener("submit", (e) => {
          e.preventDefault();
          const email = emailForm.email.value;
          console.log(`Email captured is ${email}`);
          const auth = getAuth();
          sendSignInLinkToEmail(auth, email, actionCodeSettings)
            .then(() => {
              // The link was successfully sent. Inform the user.
              // Save the email locally so you don't need to ask the user for it again
              // if they open the link on the same device.
              window.localStorage.setItem('emailForSignIn', email);
              emailModal.style.display = "block";
              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              console.log(errorCode);
              console.log(errorMessage);
              // ...
            });

          
        })
        console.log('No user Detected');
      }
    });
  }

  const setupAdminUI = (user) => {
    if (user) {
        loginOption.style.display = "none";
        loginLoader.style.display = "block";
        highLevUserItems.forEach(item => item.style.display = 'none');
        logoutButton.addEventListener("click", () => {
          signOut(auth);
        })

        
        
        let hlStatus = false;
        let customerInfo = {};

        const uid = user.uid;

        let displayName = user.displayName;
        
        document.getElementById("userName").innerHTML = displayName;

        const email = user.email;

        let photoURL = user.photoURL;
        
        document.getElementById("profilePic").src = photoURL;

        function determineUserAttributes(emailPassed){
          const populateAUserInfo = httpsCallable(functions, 'popUserInfo');
          const customerLoader = document.getElementById('customerLoader');
          customerLoader.style.display = "block";
          populateAUserInfo({ identifiedUE: emailPassed}).then((result) => {
            const data = result.data;
            customerInfo = data.recognizedCustomer;
          }).then(() =>{

            hlStatus = customerInfo.isPriviledged;
            const currentCust = customerInfo.customerID;
            let currentOrg = customerInfo.customerOrg;
            document.getElementById("organizationsManaged").innerHTML = `<i class="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-deep-purple"></i>`+"Your Organization: "+ currentOrg;
            const curretPOC = customerInfo.cusomerPOC;
            document.getElementById("pointOfContactDisplay").innerHTML = `<i class="fa fa-home fa-fw w3-margin-right w3-large w3-text-deep-purple"></i>`+`${currentOrg} Point of Contact: `+curretPOC;
            const finContact= customerInfo.customerFinAd;
            document.getElementById("financialAdmin").innerHTML = `<i class="fa fa-address-card-o fa-fw w3-margin-right w3-large w3-text-deep-purple"></i>`+"Your Financial Administrator: "+finContact;
            const finContPhone = customerInfo.customerFinAdPhone;
            document.getElementById("financialAdminPhone").innerHTML = `<i class="fa fa-phone fa-fw w3-margin-right w3-large w3-text-deep-purple"></i>`+"Financial Administrator Phone Number: "+finContPhone;
            const currentOrgBalance = customerInfo.lowLevOrgBal;
            const currentOrgDueDate = customerInfo.lowLevPayDue;

            // Set Maintenance Form
            const maintenanceForm2 = document.querySelector('.maintenanceForm2')
            const maintenanceLoader2 = document.getElementById('maintenanceLoader2')
            
            // Elevated User Set

            if (hlStatus === true){
              
              highLevUserItems.forEach(item => item.style.display = 'block');
              const paymentLoader = document.getElementById('paymentLoader2')
              
              // Grab Users High Level Docs
              const getCustomerPersonalDocs = httpsCallable(functions, 'grabDocs');
              const getTheGoods = httpsCallable(functions, 'getTheDucats');
              const cleanHouse = httpsCallable(functions, 'getAllTheDucats');

              // payment Forms-High Lev
              const paymentForm = document.querySelector(".payment-form")
              const paymentForm2 = document.querySelector(".payment-form2")

              

              paymentForm.addEventListener('submit', (e) => {
                e.preventDefault()
                if (allowPay == false){
                  console.log(`AllowPay: ${allowPay}`);
                  
                  alert("ATTENTION: There is no balance due for this organization. Please select a different organization."+
                  " If you feel this is a mistake, please contact: accounting@divinelydesigning.com")
                } else {
                  console.log(`AllowPay: ${allowPay}`);
                  paymentLoader.style.display = "block";
                                  
                  console.log("PaymentForm Clicked!");
                  createCheckoutSession(uid);
                };
                
              });

              paymentForm2.addEventListener('submit', (e) => {
                e.preventDefault()
                paymentLoader.style.display = "block";

                console.log("PaymentForm2 Clicked!");
                createCheckoutSession2(uid);

              });

              

              async function createCheckoutSession() {
                const paymentOrg = paymentForm.maintenanceDropDown3.value;
                getTheGoods({
                  loggedCustomer: currentCust, 
                  loggedOrg: paymentOrg,
                  payingEmail: email,
                }).then(async (result) => {
                  
                  const data3 = result.data;
                  const pricer = data3.ramInTheBush;

                  const sessionRef = collection(db, "payingCustomers", uid, "checkout_sessions");
                  
                  const { id: checkoutSessionId } = await addDoc(sessionRef, pricer);

                  const checkoutSessionRef = doc(sessionRef, checkoutSessionId);
                  onSnapshot(checkoutSessionRef, async (snap) => {
                    console.log(snap.data())
                    const { sessionId } = snap.data();
                    console.log(snap.data().sessionId)
                    if (snap.exists()) {
                      const stripe = await loadStripe(
                        "pk_live_51NHBEhHRN194C1NiyNVEOd1U3ugIWK8NjmmbHaJ1mTdRzM2pZDAdNFdpNCBREFeZ94CRU9IvTCXXPj95d2dPAIoI00Nr6REXXG"
                      );
                      stripe.redirectToCheckout( {sessionId});
                    }
                  });
                }).catch((error) => {
                  // Getting the Error details.
                  const code = error.code;
                  const message = error.message;
                  const details = error.details;
                  console.log(`Error code ${code} with message: ${message}. The details are ${details} `);
                  // ...
                });                      
              }

              async function createCheckoutSession2() {
                cleanHouse({
                  loggedCustomer: currentCust,
                  payingEmail: email,
                }).then(async (result) => {
                  
                  const data4 = result.data;
                  const pricer2 = data4.ramInTheBush;

                  const sessionRef = collection(db, "payingCustomers", uid, "checkout_sessions");
                  
                  const { id: checkoutSessionId } = await addDoc(sessionRef, pricer2);

                  const checkoutSessionRef = doc(sessionRef, checkoutSessionId);
                  onSnapshot(checkoutSessionRef, async (snap) => {
                    console.log(snap.data())
                    const { sessionId } = snap.data();
                    console.log(snap.data().sessionId)
                    if (snap.exists()) {
                      const stripe = await loadStripe(
                        "pk_live_51NHBEhHRN194C1NiyNVEOd1U3ugIWK8NjmmbHaJ1mTdRzM2pZDAdNFdpNCBREFeZ94CRU9IvTCXXPj95d2dPAIoI00Nr6REXXG"
                      );
                      stripe.redirectToCheckout( {sessionId});
                    }
                  });
                }).catch((error) => {
                  // Getting the Error details.
                  const code = error.code;
                  const message = error.message;
                  const details = error.details;
                  console.log(`Error code ${code} with message: ${message}. The details are ${details} `);
                  // ...
                });                      
              }


              let customerOrgs;
              let allowPay = false;

              getCustomerPersonalDocs({loggedCustomer: currentCust}).then((result) => {
                const data2 = result.data;
                customerOrgs = data2.customerOrgs;
                
                // Invoices
                const invoices = data2.invoiceObject;
                const displayInvUrl = data2.firstURL;
                const fstInv = document.getElementById("pdfInvoice");
                fstInv.setAttribute("src", displayInvUrl);
                for (const [key, value] of Object.entries(invoices)) {
                  let invEntries = document.getElementById("invList");
                  invEntries.innerHTML += `<li class="w3-hover-amber"><a href="${value}" target="_blank">${key}</li>`;
                }

                // Contracts
                const contracts = data2.contractObject;
                const displayContractUrl = data2.firstContrURL;
                const origContract = document.getElementById("pdfContract");
                origContract.setAttribute("src", displayContractUrl);

                // Amendments
                const amendments = data2.amendmentObject;
                const displayAmendmentUrl = data2.firstAmendURL;
                const origAmendment = document.getElementById("pdfAmendment");
                origAmendment.setAttribute("src", displayAmendmentUrl);
                for (const [key, value] of Object.entries(amendments)) {
                  let amendEntries = document.getElementById("amendList");
                  amendEntries.innerHTML += `<li class="w3-hover-amber"><a href="${value}" target="_blank">${key}</li>`;
                }

                // Receipts
                const receipts = data2.receiptObject;
                const displayReceiptUrl = data2.firstReceiURL;
                const origReceipt = document.getElementById("receiptLink");
                origReceipt.setAttribute("href", displayReceiptUrl);
                // const origReceipt2 = document.getElementById("pdfReceipt");
                // origReceipt2.setAttribute("src", displayReceiptUrl);
                for (const [key, value] of Object.entries(receipts)) {
                  let recEntries = document.getElementById("receiptList");
                  recEntries.innerHTML += `<li class="w3-hover-amber"><a href="${value}" target="_blank">${key}</li>`;
                }

                // Manitenance
                const maintenances = data2.maintenanceObject;
                const displayMaintenanceUrl = data2.firstMaintenURL;
                const origMaintenance = document.getElementById("pdfMaintenance");
                origMaintenance.setAttribute("src", displayMaintenanceUrl);
                for (const [key, value] of Object.entries(maintenances)) {
                  let maintEntries = document.getElementById("maintList");
                  maintEntries.innerHTML += `<li class="w3-hover-amber"><a href="${value}" target="_blank">${key}</li>`;
                }

              }).catch((error) => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log(`Error code ${code} with message: ${message}. The details are ${details} `);
                // ...
              });             

              const affiliatedOrgs = Object.keys(currentOrg)
              document.getElementById("organizationsManaged").innerHTML = `<i class="fa fa-briefcase fa-fw w3-margin-right w3-large w3-text-deep-purple"></i>`+"Organization Affiliation(s): "+ affiliatedOrgs;
              document.getElementById("pointOfContactDisplay").innerHTML = `<i class="fa fa-home fa-fw w3-margin-right w3-large w3-text-deep-purple"></i>`+`Main Point of Contact: `+curretPOC;

              // Custom Headers For Customer Docs
              document.getElementById("orgContractTitle").innerHTML = `Contracts for: ${affiliatedOrgs}`;
              document.getElementById("orgInvoiceTitle").innerHTML = `Invoices for: ${affiliatedOrgs}`;
              document.getElementById("orgMaintenanceTitle").innerHTML = `Maintenance Requests for: ${affiliatedOrgs}`;

              // Show Organization Selector
              const orgLabel = document.getElementById("orgMaintTag");
              orgLabel.style.display = "block";

              const orgPaymentLabel = document.getElementById("paymentMaintTag");
              orgPaymentLabel.style.display = "block";
              
              //Populate dropdown of Orgs for Maint Reqs
              const orgDropdown = document.getElementById("maintenanceDropDown2");
              for(let i = 0; i < affiliatedOrgs.length; i++) {
                  const opt = document.createElement('option');
                  opt.innerHTML = affiliatedOrgs[i];
                  opt.value = affiliatedOrgs[i];
                  orgDropdown.appendChild(opt);
              }
              orgDropdown.style.display = "block";
              orgDropdown.setAttribute("required","")

              // Make Dropdown Required


              // Populate dropdown of Orgs for Payment
              function dateAndBalanceUpdtr (){
                const selectedOrg = document.getElementById("maintenanceDropDown3").value;
                let dateDisplay = customerOrgs[selectedOrg].paymentDue;
                let balDisplay = parseFloat(customerOrgs[selectedOrg].balance.toFixed(2));
                if (dateDisplay == null ) { dateDisplay = "N/A"};
                
                if (balDisplay > parseFloat(0.00)) {allowPay = true} else { allowPay = false};
                console.log(balDisplay);
                console.log(`That was balDisplay`);
                console.log(`AllowPay: ${allowPay}`);
                document.getElementById("paymentDue").innerHTML = dateDisplay;
                document.getElementById("paymentBalance").innerHTML = balDisplay;
              }
              const orgPaymentDropdown = document.getElementById("maintenanceDropDown3");
              for(let i = 0; i < affiliatedOrgs.length; i++) {
                  const opt = document.createElement('option');
                  opt.innerHTML = affiliatedOrgs[i];
                  opt.value = affiliatedOrgs[i];
                  orgPaymentDropdown.appendChild(opt);
              }
              document.getElementById("paymentTotalBalance").innerHTML = customerInfo.customerBalance;
              orgPaymentDropdown.style.display = "block";
              orgPaymentDropdown.setAttribute("required","")

              const orgPaymentInfo  = document.getElementById("maintenanceDropDown3");
              orgPaymentInfo.addEventListener("change", dateAndBalanceUpdtr);
              // Make Dropdown Required

              maintenanceForm2.addEventListener('submit', (e) => {
                e.preventDefault()
                maintenanceLoader2.style.display = "block";
  
                //Get User Info
                const customer = currentCust;
                const organization = maintenanceForm2.maintenanceDropDown2.value;
                const message = maintenanceForm2.Message.value;
  
                const fileLength = document.getElementById("uploadedMaintenance2");

                if (fileLength.files.length == 0) {
                  let yourDate = new Date();
                  const offset = yourDate.getTimezoneOffset();
                  yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000));
                  yourDate = yourDate.toISOString().split('T')[0];

                  //Send Modified Organization Info Function
                  function uploadmaintenance(customer, maintOrgHolder, time, message, displayName) {
                    const callRecUploader = httpsCallable(functions, 'uploadMaint');
                    callRecUploader({
                      selectedCustomer: customer,
                      orgToDocument: maintOrgHolder,
                      message: message,
                      timestamp: time,
                      loggedCustomer : displayName,
                    }).then(() => {
                      maintenanceForm2.reset();
                      maintenanceLoader2.style.display = "none";
                      location.reload();
                    }).catch(function (err) {
                      // Getting the Error details.
                      const code = err.code;
                      const message = err.message;
                      const details = err.details;
                      console.log(`Error code ${code} with message: ${message}. The details are ${details} `);
                      console.log('Delete failed, see console,');
                      console.warn(err);
                    });
                  }
                  uploadmaintenance(customer, organization, yourDate, message, displayName);
                } else {
                  let fileReader = new FileReader();
                  //Get PDF
                  const maintFile = document.querySelector(".maintenanceInput2").files[0];
                  fileReader.readAsDataURL(maintFile);
                  fileReader.onload = function () {
                    let finalFile = fileReader.result;

                    const regex = /data:.*base64,/;
                    finalFile = finalFile.replace(regex, ""); //.replace(/^data:image\/[a-z]+;base64,/, "");
                    console.log(finalFile);
                    let uploadFileName = maintFile.name;

                    let yourDate = new Date();
                    const offset = yourDate.getTimezoneOffset();
                    yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000));
                    yourDate = yourDate.toISOString().split('T')[0];

                    //Send Modified Organization Info Function
                    function uploadmaintenance(customer, maintOrgHolder, maintToUpload, fileName, time, message, displayName) {
                      const callRecUploader = httpsCallable(functions, 'uploadMaint');
                      callRecUploader({
                        selectedCustomer: customer,
                        orgToDocument: maintOrgHolder,
                        uploadedMainRec: maintToUpload,
                        uploadedFileName: fileName,
                        message: message,
                        timestamp: time,
                        loggedCustomer: displayName,
                      }).then(() => {
                        maintenanceForm2.reset();
                        maintenanceLoader2.style.display = "none";
                        location.reload();
                      }).catch(function (err) {
                        // Getting the Error details.
                        const code = err.code;
                        const message = err.message;
                        const details = err.details;
                        console.log(`Error code ${code} with message: ${message}. The details are ${details} `);
                        console.log('Delete failed, see console,');
                        console.warn(err);
                      });
                      

                    }
                    uploadmaintenance(customer, organization, finalFile, uploadFileName, yourDate, message, displayName);
                  };
                  fileReader.onerror = function () {
                    console.log(fileReader.error);
                  };
                }
                
              })

              // Get Customers Contract + Bill + Etc...

              const currentBalance = parseFloat(customerInfo.customerBalance);
              const paySect = document.getElementById("payDateSect");
              paySect.style.display = "none";
              
              let zeroTest = parseFloat(0.00);
              zeroTest = parseFloat(zeroTest.toFixed(2));

              if (currentBalance > zeroTest){
                paySect.style.display = "block";
              }
              
            } else {

              
              const paymentLoader2 = document.getElementById('paymentLoader3');

              const forgiveDebts = httpsCallable(functions, 'getTheDucats');

              // payment Form-Low Lev
              const paymentForm3 = document.querySelector(".payment-form3")

              async function createCheckoutSession3() {
                const paymentOrg = currentOrg;
                console.log(currentCust);
                console.log(paymentOrg);
                console.log(email);
                forgiveDebts({
                  loggedCustomer: currentCust, 
                  loggedOrg: paymentOrg,
                  payingEmail: email,
                }).then(async (result) => {
                  
                  const data3 = result.data;
                  const pricer = data3.ramInTheBush;

                  const sessionRef = collection(db, "payingCustomers", uid, "checkout_sessions");
                  
                  const { id: checkoutSessionId } = await addDoc(sessionRef, pricer);

                  const checkoutSessionRef = doc(sessionRef, checkoutSessionId);
                  onSnapshot(checkoutSessionRef, async (snap) => {
                    console.log(snap.data())
                    const { sessionId } = snap.data();
                    console.log(snap.data().sessionId)
                    if (snap.exists()) {
                      const stripe = await loadStripe(
                        "pk_live_51NHBEhHRN194C1NiyNVEOd1U3ugIWK8NjmmbHaJ1mTdRzM2pZDAdNFdpNCBREFeZ94CRU9IvTCXXPj95d2dPAIoI00Nr6REXXG"
                      );
                      stripe.redirectToCheckout( {sessionId});
                    }
                  });
                }).catch((error) => {
                  // Getting the Error details.
                  const code = error.code;
                  const message = error.message;
                  const details = error.details;
                  console.log(`Error code ${code} with message: ${message}. The details are ${details} `);
                  // ...
                });                      
              }

              paymentForm3.addEventListener('submit', (e) => {
                e.preventDefault()
                if (allowPay == false) {
                  console.log(`AllowPay: ${allowPay}`);

                  alert("ATTENTION: There is no balance due." +
                    " If you feel this is a mistake, please contact: accounting@divinelydesigning.com")
                } else {
                  console.log(`AllowPay: ${allowPay}`);
                  paymentLoader2.style.display = "block";

                  console.log("PaymentForm3 Clicked!");
                  createCheckoutSession3(uid);

                };
                
              });

              maintenanceForm2.addEventListener('submit', (e) => {
                e.preventDefault()
                maintenanceLoader2.style.display = "block";
  
                //Get User Info
                const customer = currentCust;
                const organization = currentOrg;
                const message = maintenanceForm2.Message.value;

                const fileLength = document.getElementById("uploadedMaintenance2");

                if (fileLength.files.length == 0) {
                  let yourDate = new Date();
                  const offset = yourDate.getTimezoneOffset();
                  yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000));
                  yourDate = yourDate.toISOString().split('T')[0];

                  //Send Modified Organization Info Function
                  function uploadmaintenance(customer, maintOrgHolder, time, message, displayName) {
                    const callRecUploader = httpsCallable(functions, 'uploadMaint');
                    callRecUploader({
                      selectedCustomer: customer,
                      orgToDocument: maintOrgHolder,
                      message: message,
                      timestamp: time,
                      loggedCustomer : displayName,
                    }).then(() => {
                      maintenanceForm2.reset();
                      maintenanceLoader2.style.display = "none";
                      location.reload();
                    }).catch(function (err) {
                      // Getting the Error details.
                      const code = err.code;
                      const message = err.message;
                      const details = err.details;
                      console.log(`Error code ${code} with message: ${message}. The details are ${details} `);
                      console.log('Delete failed, see console,');
                      console.warn(err);
                    });
                  }
                  uploadmaintenance(customer, organization, yourDate, message, displayName);
                } else {
                  let fileReader = new FileReader();
                  //Get PDF
                  const maintFile = document.querySelector(".maintenanceInput2").files[0];
                  fileReader.readAsDataURL(maintFile);
                  fileReader.onload = function () {
                    let finalFile = fileReader.result;

                    const regex = /data:.*base64,/;
                    finalFile = finalFile.replace(regex, ""); //.replace(/^data:image\/[a-z]+;base64,/, "");
                    console.log(finalFile);
                    let uploadFileName = maintFile.name;

                    let yourDate = new Date();
                    const offset = yourDate.getTimezoneOffset();
                    yourDate = new Date(yourDate.getTime() - (offset * 60 * 1000));
                    yourDate = yourDate.toISOString().split('T')[0];

                    //Send Modified Organization Info Function
                    function uploadmaintenance(customer, maintOrgHolder, maintToUpload, fileName, time, message, displayName) {
                      const callRecUploader = httpsCallable(functions, 'uploadMaint');
                      callRecUploader({
                        selectedCustomer: customer,
                        orgToDocument: maintOrgHolder,
                        uploadedMainRec: maintToUpload,
                        uploadedFileName: fileName,
                        message: message,
                        timestamp: time,
                        loggedCustomer: displayName,
                      }).then(() => {
                        maintenanceForm2.reset();
                        maintenanceLoader2.style.display = "none";
                        location.reload();
                      }).catch(function (err) {
                        // Getting the Error details.
                        const code = err.code;
                        const message = err.message;
                        const details = err.details;
                        console.log(`Error code ${code} with message: ${message}. The details are ${details} `);
                        console.log('Delete failed, see console,');
                        console.warn(err);
                      });
                      

                    }
                    uploadmaintenance(customer, organization, finalFile, uploadFileName, yourDate, message, displayName);
                  };
                  fileReader.onerror = function () {
                    console.log(fileReader.error);
                  };
                }
              })

              let allowPay = false;

              const lowLevelPay = document.getElementById('lowLevPayDateSect');
              lowLevelPay.style.display = "none";

                      
              let zeroTest = parseFloat(0.00);
              zeroTest = parseFloat(zeroTest.toFixed(2));

              if (currentOrgBalance > zeroTest){
                allowPay = true;
                let displayDate = currentOrgDueDate;
                if (currentOrgDueDate == null){ displayDate = "N/A"} else { displayDate = currentOrgDueDate};
                document.getElementById("paymentDue2").innerHTML = displayDate;
                document.getElementById("paymentBalance2").innerHTML = currentOrgBalance;
                lowLevelPay.style.display = "block";
              }

              
            }
            // High Level Customer Set END
            // **************************

            customerLoader.style.display = "none";
          }).catch((error) => {
            // Getting the Error details.
            const code = error.code;
            const message = error.message;
            const details = error.details;
            const lineNumber = error.lineNumber;
            console.log(`Error code ${code} with message: ${message}. The details are ${details} @ Line Number: ${lineNumber}`);
            // ...
          });
        }

        determineUserAttributes(email);
        loginLoader.style.display = "none";
        profileSection.style.display = "block";
    }
  }


  monitorAuthState();

} 